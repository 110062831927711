module.exports = {
  //PARAMETERS
  developmentFeatures: false, //false or not assigned to disable development features
  captchaTokenFront: "6LcjXu0aAAAAABpuC2mj1E5H7dsJ5hQ5QThJkubd",
  stripe_pk: 'pk_live_51Io3gaISqTEwXjdevW098z802Mqs6IdfsKz9FgF92OE11sQsnfYg5Fp9UPJeJWsIW7iW0lhZ8SMbs20DQcBNvZPN00Rz3tm6f2', //PRODUCCION ELXBOX
  open_DOORS: false,
  more_payment_methods: false,
  access_code_digits: 4,
  "color-primary": '#cda077',
  "color-map-nodisponible": "#cda077", // cda077 keep this color if the company doesnt ask to change it to their primary color
  useAnalytics: false,
  use_MERCHANT: true, //USE mercantilRegister param
  use_FAX: true,
  use_LOCATION_PREP: false,
  use_StorageGroups: false,
  use_TEST_DATA: false, //AUTO FILL FORMS WITH TEST DATA

  //FEATURES
  ALLOW_CHANGE_STORAGE: false, //ALLOW CHANGE STORAGE FEATURE
  
  //CHECKOUT PARAMETERS
  USE_RETENTION: true, //Allowed 19% retention
  use_PRORRATION: false, //SHOW PRORRATION AT CHECKOUT
  use_CONTRACTS: false, //SHOW SIGNABLE CONTRACTS AT CHECKOUT
  use_ASSURE: false, //SHOW ASSURES AT CHECKOUT
  use_ASSURE_PRORRATION: false, //APPLY PRORRATION TO ASSURES AT CHECKOUT
  use_ASSURE_EXTENSION: false, //SHOW ASSURE EXTENSION
  use_DEPOSIT: false, //SHOW DEPOSIT AT CHECKOUT
  
  //ADD CUSTOMER CHECKOUT PARAMETERS
  allow_CREATE_CUSTOMER_PURCHASE: true, //ALLOWS TO CREATE A SINGLE CUSTOMER WITHOUT PURCHASE
  allow_NORMAL_PURCHASE: true, //ALLOWS NORMAL PURCHASE
  allow_LACK_PURCHASE: true, //ALLOWS LACK PURCHASE
  allow_BUY_PRODUCTS_PURCHASE: true, //ALLOWS BUY PRODUCTS PURCHASE
  allow_BOOKING_PURCHASE: false, //ALLOWS BOOKING PURCHASE

  //PAYMENT PARAMETERS
  use_CARD_PAYMENT_FOR_WORKERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_WORKERS: true, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_WORKERS: false, //ENABLE TRANSFER PAYMENTS
  use_CARD_PAYMENT_FOR_CUSTOMERS: true, //ENABLE CARD PAYMENTS
  use_SEPA_DEBIT_PAYMENT_FOR_CUSTOMERS: false, //ENABLE SEPA_DEBIT PAYMENTS
  use_TRANSFER_PAYMENT_FOR_CUSTOMERS: false, //ENABLE TRANSFER PAYMENTS
  use_PAY_AT_SHOP: true, //ENABLE TRANSFER PAYMENTS

  //PARAM NEW COMPANY
  withLanding: true,
  withIndoorOpening: false, //tiene puerta principal interna
  withStorageOpening: true, //storages doors

  companyName: "ELXBOX",
  phone: "687 905 196",
  city: "Elche",
  province: "Alicante",
  email: "info@elxboxtrasteros.com",
  address: "AVENIDA DE LA LIBERTAD NÚMERO 2, ALTILLO, 03206 ELCHE",
  postal_code: "03206",
  storageNaming: "BOX",
  commercialName: "Elxbox Trasteros",
  payments_companyName: "CONSTRUCCIONES DUNAMAR S.L.",
  payments_NIF: "B03352986",
  mercantilRegister: "Inscrita en Registro Mercantil Alicante - Tomo 1.561 Folio 111 Sección 8 Hoja A-19459",
  accountant_email: "info@elxboxtrasteros.com",
  contract_email: "info@elxboxtrasteros.com",
  url: "https://elxboxtrasteros.com",
  branch: "ELXBOX",

  //INVOICE PARAMETERS
  firstSizeM3: false,
  use_IRPF: true,
  IVA: 21, //IN PERCENT 21%
  RETENTION: 19, //IN PERCENT 19%
};
